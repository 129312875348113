<template>
  <BSpinner v-if="verificationResponse.isLoading" text="Verifying" />
  <BSpinner v-else-if="setupResponse.isLoading || skipResponse.isLoading" />
  <BSpinner v-if="banksResponse.isLoading" text="Loading" />
  <div class="px-6 pt-4 pb-12 flex flex-col min-h-screen text-sm xs:text-base" v-else>
    <p class="text-center w-full font-semibold text-lg truncate px-5">Setup Bank Account</p>

    <form @submit.prevent="handleSubmit">
      <p class="text-center text-xs xs:text-sm font-medium text-text-alternate-3 mt-6">
        Please setup your log in information to complete your account setup.
      </p>

      <div class="flex flex-col my-6">
        <label class="text-text-primary font-semibold" for="bank_number">Bank Account Number</label>
        <input
          class="
            border
            rounded
            border-border-primary
            my-2
            p-3.5
            text-sm
            font-medium
            focus:outline-none
          "
          placeholder="Account Number"
          v-model="bankNumber"
          required
          type="number"
          id="bank_number"
          pattern="\d*"
          inputmode="decimal"
          min="0"
        />
      </div>
      <div class="flex flex-col my-6">
        <label class="text-text-primary font-semibold" for="bank_name">Choose Bank</label>
        <BSelectInput class="my-2" id="bank_name" :options="bankList" v-model="bank" />
        <p
          class="text-xs flex px-3.5 font-medium text-text-danger py-2 rounded"
          style="background: #ffd9d9"
          v-if="verificationResponse.isFailure"
        >
          <ErrorIcon class="mr-3 h-4" />
          <!-- {{ verificationResponse.error }} -->
          Could not find bank account!
        </p>
        <div
          v-if="verificationResponse.data"
          class="border rounded border-border-primary p-3.5 text-xs flex"
        >
          <div class="bg-bg-alternate-3 h-11 rounded w-14"></div>
          <div class="flex flex-col justify-between px-3 py-1">
            <p class="font-semibold text-text-primary truncate">
              {{ verificationResponse.data.account_name }}
            </p>
            <p class="text-text-alternate-3 font-medium">
              {{ verificationResponse.data.account_number }}
            </p>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          class="bg-btn-bg-primary text-btn-text-primary rounded w-full mt-4 py-4 font-bold"
          type="submit"
          :disabled="!verificationResponse.data"
        >
          Add Bank
        </button>
        <button class="text-text-primary font-semibold text-sm mt-6" @click.prevent="handleSkip">
          Do Later
        </button>
      </div>
    </form>
  </div>

  <BModal v-if="setupResponse.isSuccess" @close="closeSuccessModal">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="mb-8 mt-4 mx-auto" />
      <p class="text-lg max-w-sm text-text-primary font-semibold my-4">
        Bank account details added successfully
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-10
          py-4
          font-bold
          text-sm
          xs:text-base
          focus:outline-none
        "
        @click="closeSuccessModal"
      >
        Done
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import BModal from '@/components/ui/BModal';
import BSpinner from '@/components/ui/BSpinner';
import BSelectInput from '@/components/ui/BSelectInput';
import { useApi } from '@/cmp-functions/useApi';
import { skipRegisterBank, registerBank, verifyAccount, getBanks } from '@/services/api';
// import { banks } from '@/data/banks';
import { useStore } from 'vuex';

export default {
  name: 'Bank',

  components: {
    BModal,
    BSpinner,
    BSelectInput,
  },

  setup() {
    const bank = ref('');
    const bankNumber = ref('');
    const store = useStore();
    const authUser = store.state.auth.authData;

    const isFilled = computed(() => {
      return bankNumber.value.length === 10 && bank.value.length > 0;
    });

    const [skipResponse, skipBankRegistration] = useApi(skipRegisterBank);

    const handleSkip = () => {
      skipBankRegistration(authUser.id);
    };

    watch(skipResponse, ({ isSuccess }) => {
      if (isSuccess) closeSuccessModal();
    });

    const [banksResponse, fetchBank] = useApi(getBanks);
    fetchBank(authUser.country);
    const bankList = computed(() => banksResponse.data?.map(({ name }) => name) || []);

    const router = useRouter();
    const [setupResponse, addBank] = useApi(registerBank);
    const [verificationResponse, verifyBankAccount, { setIsFailure: setIsVerificationFailure }] =
      useApi(verifyAccount);

    const closeSuccessModal = () => {
      store.commit('auth/TOGGLE_BANK_SETUP');
      router.push('/explore/my-fundraiser');
    };

    const bankDetails = computed(() => banksResponse.data.find(({ name }) => name === bank.value));

    const handleSubmit = () => {
      if (isFilled.value) {
        const { code: bankcode, name: bankname } = bankDetails.value;
        addBank({
          userid: authUser.id,
          bankcode,
          bankname,
          accountnumber: bankNumber.value,
        });
      }
    };

    watch(bankNumber, () => {
      handleVerification();
    });

    watch(bank, () => {
      handleVerification();
    });

    const handleVerification = () => {
      setIsVerificationFailure(false);
      if (!isFilled.value) return;
      const { code: bankcode } = bankDetails.value;
      verifyBankAccount({
        account_bank: bankcode,
        account_number: bankNumber.value,
      });
    };

    return {
      setupResponse,
      banksResponse,
      skipResponse,
      verificationResponse,
      setIsVerificationFailure,
      bankNumber,
      bank,
      bankList,
      handleVerification,
      closeSuccessModal,
      handleSubmit,
      handleSkip,
    };
  },
};
</script>
